/**
 * Copyright 2021 The Subscribe with Google Authors. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS-IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// Why is there a strings.ts and a swg-strings.ts, you ask? strings.ts is a
// generated file, currently used for gaa builds. This file is used for swg and
// swg-basic builds, and is currently manually updated.
// TODO(stellachui): Figure out if they should be merged without a large impact
//   on binary size.

export const SWG_I18N_STRINGS = {
  'SUBSCRIPTION_TITLE_LANG_MAP': {
    'en': 'Subscribe with Google',
    'ar': 'Google اشترك مع',
    'de': 'Abonnieren mit Google',
    'en-au': 'Subscribe with Google',
    'en-ca': 'Subscribe with Google',
    'en-gb': 'Subscribe with Google',
    'en-us': 'Subscribe with Google',
    'es': 'Suscríbete con Google',
    'es-419': 'Suscríbete con Google',
    'es-latam': 'Suscríbete con Google',
    'es-latn': 'Suscríbete con Google',
    'fr': "S'abonner avec Google",
    'fr-ca': "S'abonner avec Google",
    'hi': 'Google के ज़रिये सदस्यता',
    'id': 'Berlangganan dengan Google',
    'it': 'Abbonati con Google',
    'ja': 'Google で購読',
    'ko': 'Google 을 통한구독',
    'ms': 'Langgan dengan Google',
    'nl': 'Abonneren via Google',
    'no': 'Abonner med Google',
    'pl': 'Subskrybuj z Google',
    'pt': 'Subscrever com o Google',
    'pt-br': 'Assine com o Google',
    'ru': 'Подпиcка через Google',
    'sv': 'Prenumerera med Google',
    'th': 'สมัครฟาน Google',
    'tr': 'Google ile Abone Ol',
    'uk': 'Підписатися через Google',
    'zh-cn': '通过 Google 订阅',
    'zh-hk': '透過 Google 訂閱',
    'zh-tw': '透過 Google 訂閱',
  },
  'CONTRIBUTION_TITLE_LANG_MAP': {
    'en': 'Contribute with Google',
    'ar': 'المساهمة باستخدام Google',
    'de': 'Mit Google beitragen',
    'en-au': 'Contribute with Google',
    'en-ca': 'Contribute with Google',
    'en-gb': 'Contribute with Google',
    'en-us': 'Contribute with Google',
    'es': '	Contribuye con Google',
    'es-419': 'Contribuir con Google',
    'es-latam': 'Contribuir con Google',
    'es-latn': 'Contribuye con Google',
    'fr': 'Contribuer avec Google',
    'fr-ca': 'Contribuer avec Google',
    'hi': 'Google खाते की मदद से योगदान करें',
    'id': 'Berkontribusi dengan Google',
    'it': 'Contribuisci con Google',
    'ja': 'Google で寄付',
    'ko': 'Google을 통해 참여하기',
    'ms': 'Sumbangkan dengan Google',
    'nl': 'Bijdragen met Google',
    'no': 'Bidra med Google',
    'pl': 'Wesprzyj publikację przez Google',
    'pt': 'Contribuir utilizando o Google',
    'pt-br': 'Contribua usando o Google',
    'ru': 'Внести средства через Google',
    'sv': 'Bidra med Google',
    'th': 'มีส่วนร่วมผ่าน Google',
    'tr': 'Google ile Katkıda Bulun',
    'uk': 'Зробити внесок через Google',
    'zh-cn': '通过 Google 捐赠',
    'zh-hk': '透過 Google 提供內容',
    'zh-tw': '透過 Google 捐款',
  },
  'REGWALL_ALREADY_REGISTERED_LANG_MAP': {
    'en': 'You have registered before.',
    'ar': 'لقد سبق أن تسجّلت.',
    'de': 'Du bist bereits registriert.',
    'en-au': 'You have registered before.',
    'en-ca': 'You have registered before.',
    'en-gb': 'You have registered before.',
    'en-us': 'You have registered before.',
    'es': 'Ya te habías registrado anteriormente.',
    'es-419': 'Ya te registraste antes.',
    'fr': 'Vous vous êtes déjà inscrit.',
    'fr-ca': 'Vous vous êtes inscrit auparavant.',
    'hi': 'आपने पहले ही इसके लिए रजिस्टर कर लिया है.',
    'id': 'Anda telah mendaftar sebelumnya.',
    'it': 'Registrazione già effettuata in precedenza.',
    'ja': 'すでに登録済みです。',
    'ko': '이전에 등록한 사용자입니다.',
    'ms': 'Anda telah mendaftar sebelum ini.',
    'nl': 'Je hebt je al eerder geregistreerd.',
    'no': 'Du er allerede registrert.',
    'pl': 'Masz już wcześniejszą rejestrację.',
    'pt': 'Já se registou anteriormente.',
    'pt-br': 'Você já tem um cadastro.',
    'ru': 'Вы уже зарегистрированы.',
    'sv': 'Du har redan registrerat dig.',
    'th': 'คุณเคยลงทะเบียนแล้ว',
    'tr': 'Daha önce kaydolmuştunuz.',
    'uk': 'Ви вже зареєструвалися раніше.',
    'zh-cn': '您之前已注册。',
    'zh-hk': '您之前已註冊。',
    'zh-tw': '你已註冊這個出版品。',
  },
  'NEWSLETTER_ALREADY_SIGNED_UP_LANG_MAP': {
    'en': 'You have signed up before.',
    'ar': 'سبق أن اشتركت في النشرة الإخبارية.',
    'de': 'Du hast dich bereits angemeldet.',
    'en-au': 'You have signed up before.',
    'en-ca': 'You have signed up before.',
    'en-gb': 'You have signed up before.',
    'en-us': 'You have signed up before.',
    'es': 'Ya te has registrado anteriormente.',
    'es-419': 'Ya te registraste antes.',
    'fr': 'Vous vous êtes déjà inscrit.',
    'fr-ca': 'Vous vous êtes inscrit auparavant.',
    'hi': 'न्यूज़लेटर के लिए पहले ही साइन अप किया जा चुका है.',
    'id': 'Anda telah mendaftar sebelumnya.',
    'it': "Hai già effettuato l'iscrizione.",
    'ja': 'すでに登録されています。',
    'ko': '이전에 가입한 사용자입니다.',
    'ms': 'Anda sudah mendaftar sebelum ini.',
    'nl': 'Je hebt je al eerder aangemeld.',
    'no': 'Du er allerede registrert.',
    'pl': 'Już wcześniej się zarejestrowałeś(-aś).',
    'pt': 'Já se inscreveu anteriormente.',
    'pt-br': 'Você se inscreveu anteriormente.',
    'ru': 'Вы уже зарегистрированы.',
    'sv': 'Du har redan registrerat dig.',
    'th': 'คุณสมัครรับข้อมูลมาก่อนแล้ว',
    'tr': 'Daha önce kaydolmuştunuz.',
    'uk': 'Ви вже зареєструвалися.',
    'zh-cn': '您之前已注册。',
    'zh-hk': '您之前已訂閱。',
    'zh-tw': '你已經訂閱了。',
  },
  'REGWALL_REGISTER_FAILED_LANG_MAP': {
    'en': 'Registration failed. Try registering again.',
    'ar': 'تعذَّرت عملية التسجيل. يُرجى إعادة المحاولة.',
    'de': 'Registrierung fehlgeschlagen. Versuche es noch einmal.',
    'en-au': 'Registration failed. Try registering again.',
    'en-ca': 'Registration failed. Try registering again.',
    'en-gb': 'Registration failed. Try registering again.',
    'en-us': 'Registration failed. Try registering again.',
    'es': 'No se ha podido completar el registro. Prueba a registrarte de nuevo.',
    'es-419': 'No se pudo completar el registro. Vuelve a intentarlo.',
    'fr': "Échec de l'enregistrement. Réessayez.",
    'fr-ca': "Échec de l'inscription. Essayez de vous inscrire à nouveau.",
    'hi': 'रजिस्ट्रेशन नहीं हो सका. फिर से रजिस्टर करने की कोशिश करें.',
    'id': 'Pendaftaran gagal. Coba daftar lagi.',
    'it': 'Registrazione non riuscita. Prova a registrarti di nuovo.',
    'ja': '登録できませんでした。もう一度お試しください。',
    'ko': '등록에 실패했습니다. 다시 등록해 보세요.',
    'ms': 'Pendaftaran gagal. Cuba mendaftar lagi.',
    'nl': 'Registratie mislukt. Probeer opnieuw te registreren.',
    'no': 'Registreringen mislyktes. Prøv å registrere deg på nytt.',
    'pl': 'Rejestracja się nie udała. Spróbuj jeszcze raz się zarejestrować.',
    'pt': 'Falha no registo. Tente registar-se novamente.',
    'pt-br': 'Não foi possível fazer o registro. Tente novamente.',
    'ru': 'Ошибка регистрации. Повторите попытку.',
    'sv': 'Registreringen misslyckades. Försök att registrera dig igen.',
    'th': 'ลงทะเบียนไม่สำเร็จ ลองลงทะเบียนอีกครั้ง',
    'tr': 'Kayıt işlemi başarısız oldu. Tekrar kaydolmayı deneyin.',
    'uk': 'Помилка реєстрації. Повторіть спробу.',
    'zh-cn': '注册失败。请尝试重新注册。',
    'zh-hk': '註冊失敗。請嘗試重新註冊。',
    'zh-tw': '註冊失敗，請再試一次。',
  },
  'NEWSLETTER_SIGN_UP_FAILED_LANG_MAP': {
    'en': 'Signup failed. Try signing up again.',
    'ar': 'تعذَّرت عملية الاشتراك. يُرجى إعادة المحاولة.',
    'de': 'Anmeldung fehlgeschlagen. Versuche es noch einmal.',
    'en-au': 'Sign-up failed. Try signing up again.',
    'en-ca': 'Sign-up failed. Try signing up again.',
    'en-gb': 'Sign-up failed. Try signing up again.',
    'en-us': 'Sign-up failed. Try signing up again.',
    'es': 'No se ha podido completar la suscripción. Prueba a suscribirte de nuevo.',
    'es-419': 'Se produjo un error de registro. Vuelve a intentarlo.',
    'fr': "Échec de l'inscription. Réessayez.",
    'fr-ca': "Échec de l'inscription. Essayez de vous inscrire à nouveau.",
    'hi': 'साइन अप नहीं किया जा सका. फिर से साइन अप करने की कोशिश करें.',
    'id': 'Pendaftaran gagal. Coba daftar lagi.',
    'it': 'Iscrizione non riuscita. Prova a iscriverti di nuovo.',
    'ja': '登録できませんでした。もう一度お試しください。',
    'ko': '가입에 실패했습니다. 다시 가입해 보세요.',
    'ms': 'Daftar gagal. Cuba daftar lagi.',
    'nl': 'Aanmelding mislukt. Probeer opnieuw aan te melden.',
    'no': 'Registreringen mislyktes. Prøv å registrere deg på nytt.',
    'pl': 'Rejestracja się nie udała. Spróbuj jeszcze raz się zarejestrować.',
    'pt': 'Falha na inscrição. Tente inscrever-se novamente.',
    'pt-br': 'Não foi possível se inscrever. Tente novamente.',
    'ru': 'Не удалось зарегистрироваться. Повторите попытку.',
    'sv': 'Registreringen misslyckades. Försök att registrera dig igen.',
    'th': 'ลงชื่อสมัครใช้ไม่สำเร็จ ลองลงชื่อสมัครใช้อีกครั้ง',
    'tr': 'Kaydolma işlemi başarısız oldu. Tekrar kaydolmayı deneyin.',
    'uk': 'Помилка реєстрації. Повторіть спробу.',
    'zh-cn': '注册失败。请尝试重新注册。',
    'zh-hk': '申請失敗。請嘗試重新申請。',
    'zh-tw': '訂閱失敗，請再試一次。',
  },
  'REGWALL_ACCOUNT_CREATED_LANG_MAP': {
    'en': 'Created an account with <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>',
    'ar': 'تم إنشاء حساب باستخدام <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>.',
    'de': 'Konto bei <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph> wurde erstellt',
    'en-au':
      'Created an account with <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>',
    'en-ca':
      'Created an account with <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>',
    'en-gb':
      'Created an account with <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>',
    'en-us':
      'Created an account with <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>',
    'es': 'Has creado una cuenta con <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>',
    'es-419':
      'Se creó una cuenta con <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>',
    'fr': 'A créé un compte avec <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>',
    'fr-ca':
      'Un compte a été créé avec l\'adresse <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>',
    'hi': '<ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph> का इस्तेमाल करके, एक खाता बनाया गया',
    'id': 'Membuat akun dengan <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>',
    'it': 'È stato creato un account con l\'indirizzo <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>',
    'ja': '<ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph> でアカウントを作成しました',
    'ko': '<ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>(으)로 계정을 만들었습니다.',
    'ms': 'Membuat akaun dengan <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>',
    'nl': 'Account gemaakt met <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>',
    'no': 'Du har opprettet en konto med <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>',
    'pl': 'Utworzono konto za pomocą adresu <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>',
    'pt': 'Criou uma conta com <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>',
    'pt-br':
      'Conta criada com o e-mail <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>',
    'ru': 'Вы зарегистрировали аккаунт на адрес <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>.',
    'sv': 'Du skapade ett konto med <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>',
    'th': 'สร้างบัญชีด้วย <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>',
    'tr': '<ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph> ile bir hesap oluşturun',
    'uk': 'Обліковий запис створено за допомогою електронної адреси <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>',
    'zh-cn': '已使用 <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph> 创建帐号',
    'zh-hk': '已使用 <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph> 建立帳戶',
    'zh-tw': '已使用 <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph> 建立帳戶',
  },
  'NEWSLETTER_SIGNED_UP_LANG_MAP': {
    'en': 'Signed up with <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph> for the newsletter',
    'ar': 'تم الاشتراك في النشرة الإخبارية باستخدام <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>.',
    'de': 'Du hast dich für den Newsletter von <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph> angemeldet',
    'en-au':
      'Signed up with <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph> for the newsletter',
    'en-ca':
      'Signed up with <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph> for the newsletter',
    'en-gb':
      'Signed up with <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph> for the newsletter',
    'en-us':
      'Signed up with <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph> for the newsletter',
    'es': 'Te has suscrito a la newsletter con <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>',
    'es-419':
      'Te registraste con <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph> para recibir el boletín informativo',
    'fr': 'S\'est abonné à la newsletter avec <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>',
    'fr-ca':
      'Vous êtes inscrit au bulletin d\'information avec l\'adresse <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>',
    'hi': 'न्यूज़लेटर पाने के लिए, <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph> से साइन अप किया गया',
    'id': 'Mendaftar dengan <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph> untuk mendapatkan newsletter',
    'it': 'Iscrizione alla newsletter con l\'indirizzo <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph> effettuata',
    'ja': '<ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph> でニュースレターを登録しました',
    'ko': '<ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>(으)로 뉴스레터에 가입했습니다.',
    'ms': 'Mendaftar dengan <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph> untuk surat berita',
    'nl': 'Aangemeld met <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph> voor de nieuwsbrief',
    'no': 'Du har registrert deg for nyhetsbrevet med <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>',
    'pl': 'Zapisano się na newsletter za pomocą adresu <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>',
    'pt': 'Inscreveu-se com <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph> no boletim informativo',
    'pt-br':
      'Inscrição na newsletter feita com o e-mail <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>',
    'ru': 'Вы подписались на новостную рассылку, используя аккаунт <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>.',
    'sv': 'Du registrerade dig för nyhetsbrevet med <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>',
    'th': 'ลงชื่อเข้าใช้ด้วย <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph> สำหรับจดหมายข่าว',
    'tr': 'Bülten için <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph> ile kaydoldunuz',
    'uk': 'Ви підписалися на інформаційні листи на електронну адресу <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph>',
    'zh-cn': '已使用 <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph> 订阅简报',
    'zh-hk': '已使用 <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph> 註冊通訊',
    'zh-tw':
      '已使用 <ph name="EMAIL"><ex>user@gmail.com</ex>%s</ph> 訂閱電子報',
  },
  'NO_MEMBERSHIP_FOUND_LANG_MAP': {
    'en': 'No membership found',
    'ar': 'لم يتم العثور على أي اشتراك.',
    'de': 'Keine Mitgliedschaftsdaten gefunden',
    'en-au': 'No membership found',
    'en-ca': 'No membership found',
    'en-gb': 'No membership found',
    'en-us': 'No membership found',
    'es': 'No se han encontrado suscripciones',
    'es-419': 'No se encontró ninguna membresía',
    'fr': 'Aucun abonnement trouvé',
    'fr-ca': 'Aucun abonnement trouvé',
    'hi': 'पैसे चुकाकर ली जाने वाली कोई सदस्यता नहीं मिली',
    'id': 'Langganan tidak ditemukan',
    'it': 'Nessun abbonamento trovato',
    'ja': 'メンバーシップが見つかりません',
    'ko': '멤버십 정보를 찾을 수 없습니다.',
    'ms': 'Tiada keahlian ditemukan',
    'nl': 'Geen lidmaatschap gevonden',
    'no': 'Fant ingen abonnementer',
    'pl': 'Nie znaleziono subskrypcji',
    'pt': 'Nenhuma subscrição encontrada',
    'pt-br': 'Nenhuma assinatura foi encontrada',
    'ru': 'Подписка не найдена.',
    'sv': 'Inget medlemskap hittades',
    'th': 'ไม่พบการเป็นสมาชิก',
    'tr': 'Üyelik bulunamadı',
    'uk': 'Немає підписок',
    'zh-cn': '未找到会员资料',
    'zh-hk': '找不到會籍',
    'zh-tw': '找不到會員資料',
  },
  'CLOSE_BUTTON_DESCRIPTION': {
    'en': 'Close dialog',
    'ar': 'إغلاق مربّع الحوار',
    'de': 'Dialogfeld schließen',
    'en-au': 'Close dialog',
    'en-ca': 'Close dialog',
    'en-gb': 'Close dialog',
    'en-us': 'Close dialog',
    'es': 'Cerrar cuadro de diálogo',
    'es-419': 'Cerrar diálogo',
    'fr': 'Fermer la boîte de dialogue',
    'fr-ca': 'Fermer la boîte de dialogue',
    'hi': 'डायलॉग बॉक्स बंद करें',
    'id': 'Tutup dialog',
    'it': 'Chiudi la finestra di dialogo',
    'ja': 'ダイアログを閉じる',
    'ko': '대화상자 닫기',
    'ms': 'Tutup dialog',
    'nl': 'Dialoogvenster sluiten',
    'no': 'Lukk dialogboksen',
    'pl': 'Zamknij okno',
    'pt': 'Fechar caixa de diálogo',
    'pt-br': 'Fechar caixa de diálogo',
    'ru': 'Закрыть диалоговое окно',
    'sv': 'Stäng dialogrutan',
    'th': 'ปิดกล่องโต้ตอบ',
    'tr': 'İletişim kutusunu kapat',
    'uk': 'Закрити вікно',
    'zh-cn': '关闭对话框',
    'zh-hk': '閂對話框',
    'zh-tw': '關閉對話方塊',
  },
  'CONTRIBUTE': {
    'en': 'Contribute',
    'ar': 'مساهمة',
    'de': 'Beitragen',
    'en-au': 'Contribute',
    'en-ca': 'Contribute',
    'en-gb': 'Contribute',
    'en-us': 'Contribute',
    'es': 'Contribuir',
    'es-419': 'Contribuir',
    'fr': 'Contribuer',
    'fr-ca': 'Faire une contribution',
    'hi': 'योगदान दें',
    'id': 'Beri kontribusi',
    'it': 'Contribuisci',
    'ja': '寄付',
    'ko': '후원',
    'ms': 'Sumbang',
    'nl': 'Bijdragen',
    'no': 'Bidra',
    'pl': 'Przekaż darowiznę',
    'pt': 'Contribuir',
    'pt-br': 'Contribuir',
    'ru': 'Сделать взнос',
    'sv': 'Bidra',
    'th': 'สนับสนุน',
    'tr': 'Katkıda bulun',
    'uk': 'Зробити внесок',
    'zh-cn': '捐赠',
    'zh-hk': '資助',
    'zh-tw': '捐款',
  },
  'SUBSCRIBE': {
    'en': 'Subscribe',
    'ar': 'اشتراك',
    'de': 'Abonnieren',
    'en-au': 'Subscribe',
    'en-ca': 'Subscribe',
    'en-gb': 'Subscribe',
    'en-us': 'Subscribe',
    'es': 'Suscribirme',
    'es-419': 'Suscribirse',
    'fr': "S'abonner",
    'fr-ca': "S'abonner",
    'hi': 'सदस्यता लें',
    'id': 'Langganan',
    'it': 'Iscriviti',
    'ja': '購読',
    'ko': '구독',
    'ms': 'Langgan',
    'nl': 'Abonneren',
    'no': 'Abonner',
    'pl': 'Subskrybuj',
    'pt': 'Subscrever',
    'pt-br': 'Fazer inscrição',
    'ru': 'Подписаться',
    'sv': 'Prenumerera',
    'th': 'สมัครสมาชิก',
    'tr': 'Abone ol',
    'uk': 'Підписатися',
    'zh-cn': '订阅',
    'zh-hk': '訂閱',
    'zh-tw': '訂閱',
  },
  'ALREADY_A_CONTRIBUTOR': {
    'en': 'Already a contributor?',
    'ar': 'هل أنت مساهم حالي؟',
    'de': 'Du bist schon Beitragende/r?',
    'en-au': 'Already a contributor?',
    'en-ca': 'Already a contributor?',
    'en-gb': 'Already a contributor?',
    'en-us': 'Already a contributor?',
    'es': '¿Ya has contribuido?',
    'es-419': '¿Ya contribuyes?',
    'fr': 'Déjà contributeur ?',
    'fr-ca': 'Vous êtes déjà contributeur?',
    'hi': 'क्या आपने पहले योगदान दिया है?',
    'id': 'Sudah menjadi kontributor?',
    'it': 'Hai già dato contributi?',
    'ja': 'すでに寄付していますか？',
    'ko': '이미 후원하고 계신가요?',
    'ms': 'Sudah menjadi penyumbang?',
    'nl': 'Ben je al een bijdrager?',
    'no': 'Har du allerede bidratt?',
    'pl': 'Już przekazujesz darowizny?',
    'pt': 'Já contribui?',
    'pt-br': 'Já faz contribuições?',
    'ru': 'Уже делаете взносы?',
    'sv': 'Är du redan en bidragsgivare?',
    'th': 'หากเป็นผู้สนับสนุนอยู่แล้ว',
    'tr': 'Halihazırda katkıda bulunuyor musunuz?',
    'uk': 'Уже робите внески?',
    'zh-cn': '已是捐赠者？',
    'zh-hk': '已是資助者？',
    'zh-tw': '已經是捐款者了嗎？',
  },
  'ALREADY_A_SUBSCRIBER': {
    'en': 'Already a subscriber?',
    'ar': 'هل أنت مشترك حالي؟',
    'de': 'Du bist bereits Abonnent/in?',
    'en-au': 'Already a subscriber?',
    'en-ca': 'Already a subscriber?',
    'en-gb': 'Already a subscriber?',
    'en-us': 'Already a subscriber?',
    'es': '¿Ya te has suscrito?',
    'es-419': '¿Ya te suscribiste?',
    'fr': 'Déjà abonné ?',
    'fr-ca': 'Vous êtes déjà abonné?',
    'hi': 'क्या आपने पहले ही सदस्यता ले ली है?',
    'id': 'Sudah berlangganan?',
    'it': "Hai già l'abbonamento?",
    'ja': '購読済みですか？',
    'ko': '이미 구독 중이신가요?',
    'ms': 'Sudah menjadi pelanggan?',
    'nl': 'Ben je al abonnee?',
    'no': 'Er du allerede abonnent?',
    'pl': 'Już subskrybujesz?',
    'pt': 'Já subscreve?',
    'pt-br': 'Já é assinante?',
    'ru': 'Уже подписаны?',
    'sv': 'Prenumererar du redan?',
    'th': 'หากเป็นสมาชิกอยู่แล้ว',
    'tr': 'Halihazırda abone misiniz?',
    'uk': 'Уже підписалися?',
    'zh-cn': '已订阅？',
    'zh-hk': '已經是訂閱者？',
    'zh-tw': '已經是訂閱者了嗎？',
  },
  'THANKS_FOR_VIEWING_THIS_AD': {
    'en': 'Thanks for viewing this ad',
    'ar': 'نشكرك على مشاهدة هذا الإعلان.',
    'de': 'Vielen Dank, dass du dir diese Anzeige angesehen hast',
    'en-au': 'Thanks for viewing this ad',
    'en-ca': 'Thanks for viewing this ad',
    'en-gb': 'Thanks for viewing this ad',
    'en-us': 'Thanks for viewing this ad',
    'es': 'Gracias por ver este anuncio',
    'es-419': 'Gracias por ver este anuncio',
    'fr': "Merci d'avoir regardé cette annonce",
    'fr-ca': "Merci d'avoir regardé cette annonce",
    'hi': 'यह विज्ञापन देखने के लिए धन्यवाद',
    'id': 'Terima kasih telah melihat iklan ini',
    'it': 'Grazie per aver visualizzato questo annuncio',
    'ja': 'この広告をご覧いただきありがとうございます',
    'ko': '이 광고를 시청해 주셔서 감사합니다.',
    'ms': 'Terima kasih kerana menonton iklan ini',
    'nl': 'Bedankt dat je deze advertentie hebt bekeken',
    'no': 'Takk for at du så på denne annonsen',
    'pl': 'Dziękujemy za obejrzenie tej reklamy',
    'pt': 'Obrigado por ver este anúncio',
    'pt-br': 'Agradecemos por assistir este anúncio',
    'ru': 'Спасибо, что посмотрели эту рекламу!',
    'sv': 'Tack för att du tittade på den här annonsen',
    'th': 'ขอบคุณที่ดูโฆษณานี้',
    'tr': 'Bu reklamı görüntülediğiniz için teşekkür ederiz',
    'uk': 'Дякуємо, що переглянули це оголошення',
    'zh-cn': '感谢观看此广告',
    'zh-hk': '感謝觀看此廣告',
    'zh-tw': '感謝觀看這則廣告',
  },
  'VIEW_AN_AD': {
    'en': 'View an ad',
    'ar': 'عرض إعلان',
    'de': 'Anzeige ansehen',
    'en-au': 'View an ad',
    'en-ca': 'View an ad',
    'en-gb': 'View an ad',
    'en-us': 'View an ad',
    'es': 'Ver un anuncio',
    'es-419': 'Ver un anuncio',
    'fr': 'Visionner une annonce',
    'fr-ca': 'Afficher une annonce',
    'hi': 'कोई विज्ञापन देखें',
    'id': 'Lihat iklan',
    'it': 'Visualizza un annuncio',
    'ja': '広告を表示',
    'ko': '광고 보기',
    'ms': 'Lihat iklan',
    'nl': 'Een advertentie bekijken',
    'no': 'Se en annonsv',
    'pl': 'Obejrzyj reklamę',
    'pt': 'Mostrar um anúncio',
    'pt-br': 'Ver anúncio',
    'ru': 'Посмотреть рекламу',
    'sv': 'Se en annons',
    'th': 'ดูโฆษณา',
    'tr': 'Reklam izle',
    'uk': 'Переглянути оголошення',
    'zh-cn': '观看广告',
    'zh-hk': '觀看廣告',
    'zh-tw': '觀看廣告',
  },
  // Message ID: 7478828886861577969
  'BACK_TO_HOMEPAGE': {
    'en': 'Back to homepage',
    'ar': 'الرجوع إلى الصفحة الرئيسية',
    'de': 'Zurück zur Startseite',
    'en-au': 'Back to homepage',
    'en-ca': 'Back to homepage',
    'en-gb': 'Back to homepage',
    'en-us': 'Back to homepage',
    'es': 'Volver a la página principal',
    'es-419': 'Volver a la página principal',
    'fr': "Retourner à la page d'accueil",
    'fr-ca': "Retour à la page d'accueil",
    'hi': 'होम पेज पर वापस जाएं',
    'id': 'Kembali ke halaman beranda',
    'it': 'Torna alla home page',
    'ja': 'ホームページに戻る',
    'ko': '홈페이지로 돌아가기',
    'ms': 'Kembali kepada halaman utama',
    'nl': 'Terug naar homepage',
    'no': 'Tilbake til startsiden',
    'pl': 'Powrót do strony głównej',
    'pt': 'Volte à página inicial',
    'pt-br': 'Voltar à página inicial',
    'ru': 'Вернуться на главную страницу',
    'sv': 'Tillbaka till startsidan',
    'th': 'กลับไปที่หน้าแรก',
    'tr': 'Ana sayfaya geri dön',
    'uk': 'Назад на головну сторінку',
    'zh-cn': '返回首页',
    'zh-hk': '返回首頁',
    'zh-tw': '返回首頁',
  },
};
